import Dashboard from "./../components/Dashboard";

// Candidate 
import AllCandidate from "./../components/Candidate/AllCandidate";
import CreateCandidate from "./../components/Candidate/CreateCandidate";
import DetailCandidate from "./../components/Candidate/DetailCandidate";

import RecruiterList from '../components/recruiter/List.js'
import RecruiterEdit from '../components/recruiter/Edit'

import Settings from '../components/Settings/Settings.js'

import Locum from "../components/jobrobotix/locum/List";
import NonLocum from "../components/jobrobotix/non-locum/List";

  
const routes = [
	{ path: '/',exact:true, name: 'Dashboard', component: Dashboard, isPublic: false },
	{ path: '/candidate',exact:true, name: 'Dashboard', component: AllCandidate, isPublic: false },
	{ path: '/candidates/:id/edit',exact:true, name: 'Dashboard', component: CreateCandidate, isPublic: false },
	{ path: '/candidates/:id/details',exact:true, name: 'Dashboard', component: DetailCandidate, isPublic: false },

	{ path: '/recruiters',exact:true, name: 'recruiters', component: RecruiterList, isPublic: false },
	{ path: '/recruiters/:id/edit',exact:true, name: 'recruiters', component: RecruiterEdit, isPublic: false },
	
	{ path: '/settings',exact:true, name: 'settings', component: Settings, isPublic: false },
	
	
	{ path: '/locum',exact:true, name: 'locum', component: Locum, isPublic: false },
	{ path: '/non-locum',exact:true, name: 'non-locum', component: NonLocum, isPublic: false },
	


];

export default routes;

