import React from 'react'
import RemoteTable from "@evenlogics/whf-remote-table";
// import api from '@evenlogics/whf-api';
// import { toast } from 'react-toastify';
const AllCandidate = () => {


  // const resendEmail = (data) => {
  //   console.log(data)

  //   const payload = {
  //     to: data.email,
  //     from: data.email_from,
  //     subject: data.email_subject,
  //     template: data.email_template
  //   }
  //   api.request('post', '/public/candidates/email', payload).then((result) => {
  //     toast.success('Email Sent');
  //   }).catch((err) => {
  //     toast.error(Object.values(err.response.data.errors).toString());
  //   })
  // }
  // const resendLaboredge=()=>{

  // }

  let col = [
    {
      dataField: "first_name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "phone",
      text: "Phone",
      sort: true,
    },
    {
      dataField: "compliance",
      text: "Compliance",
      sort: true,
    },
    {
      dataField: "leap",
      text: "Leap",
      sort: true,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true
    },
    {
      dataField: "laboredge_error",
      text: "Laboredge Errors",
      sort: true,
      formatter:(cell)=>{
        return<ul>
          {cell?.split(",").map((c)=><li className='badge badge-danger'>{c}</li>)}
        </ul>
      }
    },
    // {
    //   dataField: "email_sent",
    //   text: "Email Sent",
    //   sort: true,
    //   formatter: (cell) => {
    //     return cell ? 'Sent' : 'Not Sent'
    //   }
    // },
    {
      dataField: "email_error",
      text: "Email Error",
      sort: true,
    },
    // {
    //   dataField: "resend_email",
    //   text: "Resend Email",
    //   sort: true,
    //   formatter: (cell, row) => {
    //     return <>
    //       {!row.email_sent && row.status!=='pending'&&
    //       <button 
    //         className='btn btn-info' 
    //         onClick={() => resendEmail(row)}>Resend</button>}
    //     </>
    //   }
    // },
    // {
    //   dataField: "resend_candidate",
    //   text: "Resend To Laboredge",
    //   sort: true,
    //   formatter: (cell, row) => {
    //     return <>
    //       {(row.status_id===1||row.status_id===3) && 
    //       <button 
    //         className='btn btn-warning' 
    //         onClick={() => resendLaboredge(row)}>Resend</button>}
    //     </>
    //   }
    // }
    
  ];
  return (
    <div className='allCandidate'>
      <RemoteTable
        entity="candidates"
        customEntity="candidates"
        columns={col}
        sort={[{ dataField: "first_name", order: "asc" }]}
        // disableDelete={true}
        // selectedRows={true}
        // getCustomRows={
        //   {
        //     callback: (row) => {
        //       const ids = row.map((r) => r.id)
        //       if(ids.length===0){
        //         toast.error('Please select candidates to export');
        //         return 
        //       }
        //       api.request('post', '/candidates/export', { candidate_ids: ids }).then((result) => {
        //         toast.success('Candidate Exported');
        //       }).catch((err) => {
        //         toast.error('Error Occured Exported');
        //       })
        //     },
        //     heading: 'Export to Laboredge'
        //   }
        // }
      />
    </div>
  )
}

export default AllCandidate



