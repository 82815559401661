import React, {useState} from 'react'
import { Button,Badge    } from 'reactstrap'
import RemoteTable from "@evenlogics/whf-remote-table";
import api from '@evenlogics/whf-api'
import {toast} from 'react-toastify'
export default function List() {

    const [loading, setloading] = useState(false)
    const defaultSorted = [{ dataField: 'id', order: 'desc' }];
    const columns = [
        { dataField: 'id', text: "ID", align: 'center', sort: true },
        { dataField: 'degree', text: "Degree", align: 'center', sort: true },
        { dataField: 'title', text: "Title", align: 'center', sort: true },
        { dataField: 'provider_job_id', text: "Provider Job Id", align: 'center', sort: true },
        { dataField: 'message', text: "Message", align: 'center', sort: true },
        { dataField: 'status', text: "Status", align: 'center', sort: true,formatter:(cell)=>{
            if(cell===1)
            return <Badge color="warning">Pending</Badge>
            if(cell===2)
            return <Badge color="success">Completed</Badge>
            if(cell===3)
            return <Badge color="warning">Failed</Badge>
        } }
    ]

    const fetchLocum = () => {
        setloading(true)
        api.request('get', '/jobs/locum').then((result) => {
            toast.success(result.message)
        }).catch((err) => {
            console.log(err)
            if (err.response.status === 422) {
                let { errors } = err.response.data;
                try {
                    let errorMsg = "";
                    Object.keys(errors).forEach((key) => {
                      errorMsg = errorMsg + errors[key][0] + "\n";
                    });
                    toast.error(errorMsg)
                  } catch (e) {
                    toast.error('Error! Something went wrong') 
                  }
            }else{
                toast.error('Error! Something went wrong') 
            }
        }).finally(()=>setloading(false));
    }

    return (
        <div>
            <Button color='success' onClick={fetchLocum} >{loading?'Fetching...':'Fetch Locum'}</Button>
            <RemoteTable
                entity="locum"
                customEntity="locum"
                columns={columns}
                sort={defaultSorted}
                hideDetail={true}
                disableDelete={true}
                hideActionCol={true}
            // selectedRows={true}
            />
        </div>
    )
}
