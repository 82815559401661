import React from 'react'

import RemoteTable from "@evenlogics/whf-remote-table"; 
export default function List() {

  const defaultSorted = [{ dataField: 'id', order: 'desc' }];
  const columns = [
    {
      dataField: 'id', text: "ID", align: 'center', sort: true
    },
    {
        dataField: 'name', text: "Name", align: 'center', sort: true
    },
    {
      dataField: 'recruiter_email', text: "Email", align: 'center', sort: true
  }
  ]
  return (
    <div>
      <RemoteTable
        entity="recruiters"
        customEntity="recruiters"
        columns={columns}
        sort={defaultSorted}
        hideDetail={true}
        disableDelete={true}
        // selectedRows={true}
      />
    </div>
  )
}
