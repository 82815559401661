export default [];
// export default [
//     {
//         name: 'Base',
//         component: require('@evenlogics/whf-ra-base'),
//         status: true,
//         order: 1
//     },
//     {
//         name: 'CMS',
//         status: true,
//         component: require('@evenlogics/whf-ra-cms'),
//         order: 2
//     },
//     {
//         name: 'Ecommerce',
//         component: require('@evenlogics/whf-ra-ecommerce'),
//         status: true,
//         order: 3
//     },
//     {
//         name: 'Product',
//         component: require('@evenlogics/whf-ra-product'),
//         status: true,
//         order: 3
//     },
//     {
//         name: 'Multistore',
//         component: require('@evenlogics/whf-ra-multistore'),
//         status: true,
//         order: 4
//     },
//     {
//         name: 'Entity',
//         component: require('@evenlogics/whf-ra-entity'),
//         status: true,
//         order: 5
//     },
//     {
//         name: 'Notifier',
//         component: require('@evenlogics/whf-ra-notifier'),
//         status: true,
//         order: 6
//     },
//     {
//         name: 'Customer',
//         component: require('@evenlogics/whf-ra-customer'),
//         status: true,
//         order: 7
//     },
//     {
//         name: 'User',
//         component: require('@evenlogics/whf-ra-user'),
//         status: true,
//         order: 8
//     },
//     {
//         name: 'Moderation',
//         component: require('@evenlogics/whf-ra-moderation'),
//         status: true,
//         order: 9
//     },
//     {
//         name: 'Payment',
//         component: require('@evenlogics/whf-ra-payment'),
//         status: true,
//         order: 10
//     },
//     {
//         name: 'Classified',
//         component: require('@evenlogics/whf-ra-classified'),
//         status: true,
//         order: 11
//     },
//     {
//         name: 'Shipping',
//         component: require('@evenlogics/whf-ra-shipping'),
//         status: true,
//         order: 12
//     },
//     {
//         name: 'Service',
//         component: require('@evenlogics/whf-ra-service'),
//         status: true,
//         order: 13
//     },
//     {
//         name: 'Wishlist',
//         component: require('@evenlogics/whf-ra-wishlist'),
//         status: true,
//         order: 14
//     },
//     {
//         name: 'Review',
//         component: require('@evenlogics/whf-ra-review'),
//         status: true,
//         order: 15
//     },
//     {
//         name: 'Template',
//         component: require('@evenlogics/whf-ra-template'),
//         status: false,
//         order: 16
//     }
// ];