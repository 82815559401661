export default {
	candidate: {
		_tag: 'candidate',
		name: 'candidate',
		phrase: 'Candidate',
		to: '/candidate',
		order: 1,
		icon: 'fas fa-user',
		roles:['admin']
		// children:{
		// 	candidate: {
		// 		_tag: 'candidate',
		// 		name: 'candidate',
		// 		phrase: 'Candidates',
		// 		to: '/candidate',
		// 		order: 1,
		// 		icon: 'fas fa-users',
		// 	},
			
		
		// }
	},
	recuiters: {
		_tag: 'recuiters',
		name: 'recuiters',
		phrase: 'Recuiters',
		to: '/recruiters',
		order: 2,
		icon: 'fas fa-users',
		roles:['admin']
	},
	
	locum: {
		_tag: 'locum',
		name: 'locum',
		phrase: 'Locum',
		to: '/locum',
		order: 4,
		icon: 'fa fa-file',
		roles:['jobrobotix-admin']
	},
	nonlocum: {
		_tag: 'non-locum',
		name: 'non-locum',
		phrase: 'Non-Locum',
		to: '/non-locum',
		order: 5,
		icon: 'fa fa-folder',
		roles:['jobrobotix-admin']
	},
	settings: {
		_tag: 'settings',
		name: 'settings',
		phrase: 'Settings',
		to: '/settings',
		order: 6,
		icon: 'fa fa-cog',
		roles:['admin','jobrobotix-admin']
	},
};