import React from 'react'
import {useParams} from 'react-router-dom'
import { FormGenerator } from "@evenlogics/whf-form-generator";
export default function Edit() {

    let {id} = useParams();

    const fields = {
        name: {
          type: "text",
          name: "name",
          label:"name",
          col: 4,
          readOnly:true
        },
        email: {
            type: "email",
            name: "recruiter_email",
            label:"Email",
            col: 4  
          }
    }
  return (
    <div>
        
        <FormGenerator
            targetEntity={`recruiters`}
            fields={fields}
            targetId={id}
            name="recruiters"
            debug={false}

            
            // showDetail={false}
            // hideDetail={true}
            // redirect="candidates"
      />
    </div>
  )
}
