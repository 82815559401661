import React,{useEffect,useState} from 'react'
import { FormGenerator } from "@evenlogics/whf-form-generator";
import api from '@evenlogics/whf-api';

export default function Settings() {
    
    const [email, setEmail] = useState('')

    useEffect(()=>{
        api.request('get','/email').then((res)=>{
            setEmail(res.email)
        }).catch(()=>{

        })
    },[])

    const emailFields = {
        email: {
            type: "text",
            name: "email",
            label: "Email",
            col: 4,
        },
        email_confirmation: {
            type: "text",
            name: "email_confirmation",
            label: "Email Confirmation",
            col: 4,
        }
    }
    const passwordFields = {
        password: {
            type: "password",
            name: "password",
            label: "Password",
            col: 4,
        },
        password_confirmation: {
            type: "password",
            name: "password_confirmation",
            label: "Password Confirmation",
            col: 4,
        },
        current_password: {
            type: "password",
            name: "current_password",
            label: "Current Password",
            col: 4,
        },
    }

    return (
        <div>
            <div className='card'>
                <div className='card-body'>
                    <div className='badge badge-primary'>Current Email: {email}</div>
                    <h5>Change Email</h5>
                    <FormGenerator
                        targetEntity={`email`}
                        fields={emailFields}
                        extraVals={{ _method: 'PUT' }}
                        name="email"
                        debug={false}
                        redirect="settings"
                    />
                </div>

            </div>
            <div className='card'>
                <div className='card-body'>
                <h5>Change Email</h5>
                    <FormGenerator
                        targetEntity={`update-password`}
                        fields={passwordFields}

                        extraVals={{ _method: 'PUT' }}

                        name="update-password"
                        debug={false}
                        redirect="settings"
                    />
                </div>

            </div>
        </div>

    )
}
