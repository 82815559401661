import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { FormGenerator } from "@evenlogics/whf-form-generator";
import _ from 'lodash'
const CreateCandidate = () => {
  let { id } = useParams();

  // ########## state for Specialities
  const [professionId, setProfessionId] = useState(0);
  const [professionPrimaryId, setProfessionPrimaryId] = useState(0);
  const [initialValues,setInitialValues]=useState({})

  const [professionSearch, setProfessionSearch] = useState('')

  console.log(professionPrimaryId,initialValues)

  const fields = {
    first_name: {
      type: "text",
      name: "first_name",
      label: "First Name",
      col: 4,
    },
    last_name: {
      type: "text",
      name: "last_name",
      label: "Last Name",
      col: 4,
    },
    email: {
      type: "email",
      name: "email",
      label: "Email",
      col: 4,
    },
    address: {
      type: "text",
      name: "address",
      label: "Address",
      col: 4,
    },
    phone: {
      type: "text",
      name: "phone",
      label: "Phone",
      col: 4,
    },

    referral_source_id: {
      type: "advanceSelect",
      label: "Referral Source",

      target: "referrals?limit=99999999999",
      // async: true,
      optionValue: "id",
      optionLabel: "label",
      name: "referral_source_id",
      col: 4 + " col-lg-4 col-md-6 ",
    },

    profession: {
      type: "advanceSelect",
      label: "Profession",

      target: `professions?limit=99999999999&q=${professionSearch}`,
      async: true,
      optionValue: "id",
      optionLabel: "label",
      name: "profession_id",
      col: 4 + " col-lg-4 col-md-6 ",
      callback: (ev) => {
        setTimeout(() => {
          setProfessionId(ev.value);

        }, 0);
        // console.log(ev)
      },
      onInputChange:(e)=>{
         _.debounce(()=>setProfessionSearch(e), 5000, true);
        
      },


    },


    specialities: {
      type: "advanceSelect",
      label: "Specialities",
      target: `professions/${professionId}/specialities?limit=99999999999`,
      async: true,
      optionValue: "id",
      optionLabel: "label",
      name: "speciality_id",
      col: 4 + " col-lg-4 col-md-6 ",
      // defaultSelected: professionPrimaryId,
      callback: (ev) => {
        setTimeout(() => {
          setProfessionPrimaryId(ev.value);
        }, 100);
      },
    },
    primary_speciality_id: {
      type: "advanceSelect",
      label: "Primary Speciality",
      target: `professions/${professionId}/specialities?limit=99999999999`,
      async: true,
      optionValue: "id",
      optionLabel: "label",
      name: "primary_speciality_id",
      col: 4 + " col-lg-4 col-md-6 ",

    },
    recruiter_id: {
      type: "advanceSelect",
      label: "Recruiters",
      target: `recruiters?limit=99999999999`,
      async: true,
      optionValue: "id",
      optionLabel: "name",
      name: "recruiter_id",
      col: 4 + " col-lg-4 col-md-6 ",

    },


    state_id: {
      type: "advanceSelect",
      label: "State",

      target: "states?limit=99999999999",
      // async: true,
      optionValue: "id",
      optionLabel: "label",
      name: "state_id",
      col: 4 + " col-lg-4 col-md-6 ",
    }

  };



  return (
    <div className='card'>
      <div className='card-body'>

      <FormGenerator
        targetEntity={`candidates`}
        fields={fields}
        targetId={id}

        getInitialValues={(data) => {
          // console.log("data :",data);
          setProfessionId(data.profession_id);
           setInitialValues(data)
        }}
      
        name="candidates"
        debug={false}
        redirect="candidate"
      />
      </div>
    </div>
  )
}

export default CreateCandidate;